import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TiSocialFacebook, TiSocialInstagram, TiSocialLinkedin } from "react-icons/ti";
import './Footer.css'

const Footer = () => {
    const location = useLocation();
    const isProductPage = location.pathname.match(/^\/product\/[a-zA-Z0-9]+$/);
    return (
        <footer className={isProductPage ? "product-footer" : "footer"}>
            <div className="left">
                <div className="tagline">
                    <h3>JAWD</h3>
                    <p>Radiate your persona</p>
                </div>
                <div className="links">
                    <Link to="/">
                        <a href="/">Home</a>
                    </Link>
                    <Link to="/exchange/policy">
                        <a href="/">Return Policy</a>
                    </Link>
                    <Link to="/shipping/policy">
                        <a href="/">Shipping Policy</a>
                    </Link>
                    <Link to="/privacy/policy">
                        <a href="/">Privacy Policy</a>
                    </Link>
                    <Link to="/about">
                        <a href="/">About Us</a>
                    </Link>
                </div>
                <div className="social">
                    <a href="https://www.facebook.com/profile.php?id=61563445618366"><TiSocialFacebook size={30} /></a>
                    <a href="https://www.instagram.com/jawdlifestyle/"><TiSocialInstagram size={30} /></a>
                    <a href="https://www.linkedin.com/company/104739810"><TiSocialLinkedin size={30} /></a>
                </div>
            </div>
            <div className="right">
                <p><b>Reg. Address:</b> 194/1 B.M. Saha Road, Uttarpara, Kolkata 712233, West Bengal</p>
            </div>
            {/* <div className="bottom">
                JAWD.2023-24-All rights reserved.
            </div> */}
        </footer>
    );
};

export default Footer;