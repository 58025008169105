import React, { useEffect, useState, useContext } from "react";
import { Helmet } from 'react-helmet'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./ProdDesc.css";
import Navbar from "../../component/Navbar/Navbar";
import TopProductMobile from "../../component/TopProductMobile/TopProductMobile";
import FAQ from "../../component/FAQ/FAQ";
import { ProductDescFetchAPI } from "../../services/Services";
import { CheckoutIdContext } from "../../helper/Context";
import Footer from "../../component/Footer/Footer";
import { ToastContainer, toast } from "react-toastify";
import NavTop from "../../component/NavTop/NavTop";
import SuggestElements from "../../component/SuggestElements/SuggestElements";
import TwoProductCombo from "../../component/TwoProductCombo/TwoProductCombo";
import getColorName from "../../Utils/HexColorConversion.js/HexColor";
import { AiOutlineCheck } from 'react-icons/ai';
import PincodeAvl from "../../component/PinCodeAvailibility/PincodeAvl";

const ProdDesc = () => {
  const Cart = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cart_white.png?updatedAt=1723459507583?tr=f-auto,q_auto"
  const Star = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/star.png?updatedAt=1723459510455?tr=f-auto?q_auto"
  const ChinosSizeGuide = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/chinos_size_chart(1).png?updatedAt=1724680688524?tr=f-auto,q_auto"
  const CargoSizeGuide = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/cargo_size_chart.png?updatedAt=1724692892141?tr=f-auto,q_auto"
  const JoggersSizeGuide = "https://ik.imagekit.io/ecombrand/JAWD%20Static%20Images/joggers_size_chart.png?updatedAt=1724693725451?tr=f-auto,q_auto"
  const navigate = useNavigate();
  const location = useLocation();
  const isProductPage = location.pathname.includes("/product");
  const [detailsFocus, setDetailsFocus] = useState(true);
  const [shippingFocus, setShippingFocus] = useState(false);
  const [returnFocus, setReturnFocus] = useState(false);
  const [redirectPath, setRedirectPath] = useState("");
  const [data, setData] = useState("");
  const [selectedColorIndex, setSelectedColorIndex] = useState(0); // Default to first color code
  const [selectedImage, setSelectedImage] = useState([]);
  const [mainImg, setMainImg] = useState("");
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [selectedSizeIndex2, setSelectedSizeIndex2] = useState(0);
  const [selectedSizeIndex3, setSelectedSizeIndex3] = useState(0);
  const [isProductInCart, setIsProductInCart] = useState(false);
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [series, setSeries] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { checkoutProdId, setCheckoutProdId } = useContext(CheckoutIdContext)
  const { id } = useParams();



  useEffect(() => {
    if (id) {
      // Handle TotalViewedProducts
      let totalViewedProducts = JSON.parse(localStorage.getItem('TotalViewedProducts')) || [];
      if (!totalViewedProducts.includes(id)) {
        totalViewedProducts.push(id);
        localStorage.setItem('TotalViewedProducts', JSON.stringify(totalViewedProducts));
      }

      // Handle recentlyViewed
      let recentlyViewed = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
      if (!Array.isArray(recentlyViewed)) {
        recentlyViewed = []; // Ensure it's an array
      }
      // Only add the ID if it's not the same as the last one added
      if (recentlyViewed[recentlyViewed.length - 1] !== id) {
        recentlyViewed.push(id);
        if (recentlyViewed.length > 5) {
          recentlyViewed.shift(); // Remove the oldest ID if array length exceeds 5
        }
        localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
      }
    }
  }, [id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const ProductObject = {
    details:
      "Introducing our premium white collar t-shirt, a timeless addition to your wardrobe that effortlessly combines style and comfort...",
    shipping:
      "As soon as you place an order, you will be notified with the necessary information through your mail id.  We will process the order within 1-2 working days. We have partnered with multiple reputed third party logistics company to deliver your order pan India seamlessly. Expect to get your order delivered maximum by 4 to 10 working days from the date of placing the order, as the time depends on the pin-code of the receiver’s address. Don’t worry about the date range, most of our customers typically get the order delivered within 3-4 working days. For more information please refer to our shipping policy.",
    return:
      "Our return policy aims to provide peace of mind to our customers...",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ProductDescFetchAPI(id)
      .then((res) => {
        console.log(res.data);
        setType(res.data.type);
        setCategory(res.data.category);
        setSeries(res.data.series);
        setData(res.data);

        // Apply Cloudinary transformations to the selected images
        const transformedImages = res.data.availableColor[0]?.images
          ?.filter((image) => !image.includes("MainImg"))
          .map((image) => image.replace('/upload/', '/upload/f_auto,q_auto/')) || [];

        setSelectedImage(transformedImages); // Default to images of first color code

        const mainImg = res.data.availableColor[0]?.images
          ?.find((image) => image.includes("MainImg"))
          .replace('/upload/', '/upload/f_auto,q_auto/') || "";

        setMainImg(mainImg); // Default to main image of first color code
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);


  // useEffect(() => {
  //   if (data && data.category === "combo") {
  //     setSelectedColors(data.availableColor.slice(0, 3));
  //     console.log("The colors are initially: ", selectedColors);

  //     setSelectedSizes(Array(3).fill(0));
  //   } else if (data) {
  //     setSelectedColors([data.availableColor[0]]);
  //     setSelectedSizes([0]);
  //   }
  // }, [data]);

  const handleDetails = () => {
    setDetailsFocus(true);
    setShippingFocus(false);
    setReturnFocus(false);
  };
  const handleShipping = () => {
    setDetailsFocus(false);
    setShippingFocus(true);
    setReturnFocus(false);
  };
  const handleReturn = () => {
    setDetailsFocus(false);
    setShippingFocus(false);
    setReturnFocus(true);
  };
  const handleAddToCart = () => {
    setCheckoutProdId(true);
    const selectedColor = data.availableColor[selectedColorIndex];
    const selectedSize1 = data.availableSizes[selectedSizeIndex];
    const selectedSize2 = data.availableSizes[selectedSizeIndex2];

    let cartItem;
    if (category.includes("combo")) {
      const selectedColorsString = selectedColors.join("-");
      const selectedSizesString = selectedSizes.join("-");
      cartItem = `${id}_${selectedColorsString}_${selectedSizesString}`;

      if (selectedColors.length == 3) {
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        if (!cartItems.includes(cartItem)) {
          cartItems.push(cartItem);
          localStorage.setItem("cartItems", JSON.stringify(cartItems));
        }
        setIsProductInCart(true);
      } else {
        toast.error("Please select two colors to proceed", { autoClose: 4000 })
      }
    } else {
      if (type.includes("couple")) {
        cartItem = `${id}_${selectedColor.code}_${selectedSize1}-${selectedSize2}`;
      } else {
        cartItem = `${id}_${selectedColor.code}_${selectedSize1}`;
      }
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (!cartItems.includes(cartItem)) {
        cartItems.push(cartItem);
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }
      setIsProductInCart(true);
    }

    console.log("Selected color: ", selectedColor.name);
    console.log(cartItem);

    // if(selectedColors.length == 3) {


    // }
  };
  const handleBuyNow = () => {
    const selectedColor = data.availableColor[selectedColorIndex];
    const selectedSize1 = data.availableSizes[selectedSizeIndex];
    const selectedSize2 = data.availableSizes[selectedSizeIndex2];

    let buyNowItem;
    if (category.includes("combo")) {
      const selectedColorsString = selectedColors.join("-");
      console.log(selectedColorsString);
      const selectedSizesString = selectedSizes.join("-");
      console.log(selectedSizesString);
      buyNowItem = `${id}_${selectedColorsString}_${selectedSizesString}`;
    } else {
      if (type.includes("couple")) {
        buyNowItem = `${id}_${selectedColor.code}_${selectedSize1}-${selectedSize2}`;
      } else {
        buyNowItem = `${id}_${selectedColor.code}_${selectedSize1}`;
      }
    }

    console.log("Selected color: ", selectedColor.name);
    console.log(buyNowItem);

    localStorage.setItem("buyNowItem", buyNowItem);
    if (category.includes("combo")) {
      if (selectedColors.length == 3) {
        navigate("/checkout");
      } else {
        toast.error("Please Select Three Colors To Proceed", 4000)
      }
    } else {
      navigate("/checkout");
    }
  };
  useEffect(() => {
    console.log(checkoutProdId);
  }, []);
  const handleColorClick = (color, colorIndex) => {
    setSelectedColorIndex(colorIndex);
    console.log("Color is: ", color);
    console.log("Color Index is: ", colorIndex);

    const cardImage = color.images.filter((image) => !image.includes("MainImg"));
    const mainImgConst = color.images.find((image) => image.includes("MainImg"));
    setMainImg(mainImgConst);
    console.log(mainImg);
    setSelectedImage(cardImage);
    console.log(cardImage);

    if (category.includes("combo")) {
      setSelectedColors(prevSelectedColors => {
        let newSelectedColors = [...prevSelectedColors];
        const colorCode = color.code.toString();

        if (newSelectedColors.includes(colorCode)) {
          // Remove the color if it's already selected
          newSelectedColors = newSelectedColors.filter(c => c !== colorCode);
        } else {
          if (newSelectedColors.length < 3) {
            // Add the color if there is space
            newSelectedColors.push(colorCode);
          } else {
            // Remove the first color and add the new one if the array is full
            newSelectedColors.shift();
            newSelectedColors.push(colorCode);
          }
        }

        console.log("Selected colors for combos are: ", newSelectedColors);
        return newSelectedColors;
      });
    }
  };



  const handleSizeClick = (sizeIndex, isSecond = false) => {
    if (isSecond) {
      setSelectedSizeIndex2(sizeIndex);
    } else {
      setSelectedSizeIndex(sizeIndex);
    }
  };
  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setIsProductInCart(cartItems.some((item) => item.startsWith(id)));
  }, [id, isProductInCart, handleAddToCart]);

  const getSizeLabels = () => {
    if (type.includes("hw")) {
      return ["Men's Size", "Women's Size"];
    } else if (type.includes("fd")) {
      return ["Father's Size", "Daughter's Size"];
    } else {
      return ["Available Sizes", "Partner's Size"];
    }
  };

  //Adding force commit
  const [firstSizeLabel, secondSizeLabel] = getSizeLabels();
  useEffect(() => {
    if (category.includes("combo") && selectedColors.length > 0) {
      const initialSizes = selectedColors.map(() => data.availableSizes[0]);
      setSelectedSizes(initialSizes);
      console.log("Selected sizes are:", selectedSizes);
    }
  }, [selectedColors, id, category, data.availableSizes]);

  const handleMultipleSizes = (sizeIndex, colorIndex) => {
    setSelectedSizes(prevSelectedSizes => {
      const newSelectedSizes = [...prevSelectedSizes];
      newSelectedSizes[colorIndex] = data.availableSizes[sizeIndex];
      return newSelectedSizes;
    });
  };

  const metaTitle = `${data.productName} - Buy Now | JAWD India`;
  let metaDescription = data.productDescription;
  let metaKeywords = '';
  if (data.productDescription) {
    const commentIndex = metaDescription.indexOf('//');
    const underscoreIndex = metaDescription.indexOf('_');
    if (commentIndex !== -1) {
      metaDescription = metaDescription.substring(0, commentIndex).trim();
      metaKeywords = data.productDescription.substring(commentIndex + 2).trim();
    }
    if (underscoreIndex !== -1) {
      metaDescription = metaDescription.substring(0, underscoreIndex).trim();
    }
    metaDescription = metaDescription.replace(/;/g, '');
    metaKeywords = `${metaKeywords}`;
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="product-description">
      {category.includes("pick_any_two") ? (<TwoProductCombo />) : (
        <>
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={metaKeywords} />
            <link rel="icon" href={selectedImage[0]} type="image/x-icon" />
          </Helmet>
          <Navbar />
          <NavTop />
          <div className="top-product-mobile">
            <TopProductMobile />
          </div>
          <div className="product-description-holder">
            <div className="img-and-desc">
              <div className="imgs">
                <div className="main-prod-img">
                  <img src={mainImg} />
                </div>
                <div className="sub-prod-img">
                  <div className="sub-img">
                    <img src={selectedImage[0]} />
                  </div>
                  <div className="sub-img">
                    <img src={selectedImage[1]} />
                  </div>
                  <div className="sub-img">
                    <img src={selectedImage[2]} />
                  </div>
                  <div className="sub-img">
                    <img src={selectedImage[3]} />
                  </div>
                </div>
              </div>
              <div className="detailed-description">
                <h1>{data.productName}</h1>
                <div className="prod-speciality">
                  <img src={Star} alt="Star" />
                  <p style={{ fontFamily: '"Lato", sans-serif' }}>This product is rated <span style={{ fontWeight: "600" }}>{data.productShortDescription}</span> stars by users</p>
                </div>
                <div className="detailed-pricing">
                  <p className="real-price">
                    <del>₹{data.originalPrice}</del>
                  </p>
                  <div className="price-pct-holder">
                    <h3 className="orginial-price-prod-des">₹{data.discountedPrice}</h3>
                    <h3 className="percentage-off">
                      -{Math.round(((data.originalPrice - data.discountedPrice) / data.originalPrice) * 100)}%
                    </h3>
                  </div>
                </div>
                <div className="line"></div>
                {!category.includes("outfit") && (

                  <div className="available-colors">
                    {category.includes("combo") ? (<p>Select Any Three Colors</p>) : (<p>Available Colors:</p>)}
                    <div className="color-boxes-for-prod">
                      {data &&
                        data.availableColor &&
                        data.availableColor.map((color, colorIndex) => (
                          <div key={colorIndex} className="color-box-wrapper">
                            <div
                              className={`${selectedColors.includes(color.code) ? "selected-color" : "color-box-for-prod"}`}
                              style={{ backgroundColor: color.code }}
                              onClick={() => handleColorClick(color, colorIndex)}
                            ></div>
                            {selectedColors.includes(color.code) && (
                              <span className="tick"><AiOutlineCheck size={10} color="#fff" /></span>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="available-size">
                  {category.includes("outfit") && (<p style={{ fontFamily: '"Lato", sans-serif' }}><b>*Note:</b> Here the sizes are in pair. "S", "M" etc. are the sizes of the T-shirt & "30", "32" are the sizes of bottomwear. So, "S 32" means combo of S sized T-shirt, 32 sized bottomwear & free size printed cap</p>)}
                  {!category.includes("combo") && (
                    <>
                      <p>{firstSizeLabel}</p>
                      <div className="sizes">
                        {data &&
                          data.availableSizes &&
                          data.availableSizes.map((size, sizeIndex) => (
                            <div
                              key={sizeIndex}
                              className={`size ${selectedSizeIndex === sizeIndex ? "selected" : ""}`}
                              onClick={() => handleSizeClick(sizeIndex)}
                            >
                              {category.includes("outfit") ? (
                                size.split("-").map((part, index) => (
                                  <div style={{ fontFamily: '"Lato", sans-serif' }} key={index}>{part}</div>
                                ))
                              ) : (
                                size
                              )}
                            </div>
                          ))}
                      </div>
                      {type.includes("couple") && (
                        <>
                          <p>{secondSizeLabel}</p>
                          <div className="sizes">
                            {data &&
                              data.availableSizes &&
                              data.availableSizes.map((size, sizeIndex) => (
                                <div
                                  key={sizeIndex}
                                  style={{ fontFamily: '"Lato", sans-serif' }}
                                  className={`size ${selectedSizeIndex2 === sizeIndex ? "selected" : ""}`}
                                  onClick={() => handleSizeClick(sizeIndex, true)}
                                >
                                  {size}
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {category.includes("combo") && (
                    <>
                      {selectedColors && selectedColors.map((color, colorIndex) => (
                        <div key={colorIndex}>
                          <p>Select size for {getColorName(color)}</p>
                          <div className="sizes">
                            {data &&
                              data.availableSizes &&
                              data.availableSizes.map((size, sizeIndex) => (
                                <div
                                  key={sizeIndex}
                                  className={`size ${selectedSizes[colorIndex] === size ? "selected" : ""}`}
                                  style={{ fontFamily: '"Lato", sans-serif' }}
                                  onClick={() => handleMultipleSizes(sizeIndex, colorIndex)}
                                >
                                  {size}
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {(category.includes("chinos") || category.includes("cargo") || category.includes("joggers") || series.includes("chinos") || series.includes("cargo") || series.includes("joggers")) && (
                    <div className="size-chart" onClick={openModal}>
                      <p style={{ fontSize: "18px", textDecoration: "underline", color: "#160363" }}>Size Guide ></p>
                    </div>
                  )}

                  <div className={`modal-overlay ${isModalOpen ? 'open' : ''}`} onClick={closeModal}>
                    <div
                      className={`modal-content ${isModalOpen ? 'slide-in' : 'slide-out'}`}
                      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                    >
                      <button className="close-button" onClick={closeModal}>
                        &times;
                      </button>
                      <div className="modal-body">
                        <table>
                          <thead>
                            <tr>
                              <th>Waist</th>
                              <th>Hip</th>
                              <th>Length</th>
                              <th>Thigh</th>
                              <th>Calf</th>
                              <th>Navel to Hip</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>28"</td>
                              <td>34"</td>
                              <td>40"</td>
                              <td>22"</td>
                              <td>14"</td>
                              <td>10"</td>
                            </tr>
                            <tr>
                              <td>30"</td>
                              <td>36"</td>
                              <td>42"</td>
                              <td>23"</td>
                              <td>15"</td>
                              <td>10.5"</td>
                            </tr>
                            <tr>
                              <td>32"</td>
                              <td>38"</td>
                              <td>44"</td>
                              <td>24"</td>
                              <td>16"</td>
                              <td>11"</td>
                            </tr>
                            <tr>
                              <td>34"</td>
                              <td>40"</td>
                              <td>46"</td>
                              <td>25"</td>
                              <td>17"</td>
                              <td>11.5"</td>
                            </tr>
                            <tr>
                              <td>36"</td>
                              <td>42"</td>
                              <td>48"</td>
                              <td>26"</td>
                              <td>18"</td>
                              <td>12"</td>
                            </tr>
                          </tbody>
                        </table>
                        {(series.includes("chinos") || category.includes("chinos")) && (<img src={ChinosSizeGuide} alt="Chinos Size Guide" className="size-guide-image" />)}
                        {(series.includes("cargo") || category.includes("cargo")) && (<img src={CargoSizeGuide} alt="Chinos Size Guide" className="size-guide-image" />)}
                        {(series.includes("joggers") || category.includes("joggers")) && (<img src={JoggersSizeGuide} alt="Chinos Size Guide" className="size-guide-image" />)}
                        <div className="size-guide-text">
                          <h3>How to Measure the Perfect Size for You</h3>
                          <p>Hey, are you wondering about the perfect size? Don't worry and follow the guidelines below.</p>
                          <ol>
                            <li>Keep your body relaxed and straight to get the precise dimension.</li>
                            <li>Waist: Measure around your natural waistline (preferably covering the navel area).<br />
                              <strong>Note:</strong> If the measurement of the waist is a fraction or odd number or both, e.g. if it is 33.5 inches, go for 34 inches.
                            </li>
                            <li>Length: Measure from waistline to the ankle.<br />
                              <strong>Note:</strong> Our Chinos are of standard length, you can cut it as per your preference. It is recommended to get one size larger to get a better experience.
                            </li>
                            <li>Sizes may vary by +/- 0.5 inches.</li>
                            <li>Size of Thigh and Calf will be increased and decreased as per standard sizing of Hip and Length. But if you are particular about your thigh and calf, you can refer to the demo picture and choose your perfect size.</li>
                          </ol>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="btn-prod-desc-holder">
                    {!isProductInCart ? (
                      <div className="btns-prod">
                        <div onClick={handleAddToCart} className="add-to-cart-btn">
                          <img src={Cart} alt="Cart" />
                          <p>Add to Cart</p>
                        </div>
                      </div>
                    ) : (
                      <div className="btns-prod">
                        <button className="add-to-cart-btn" disabled={true}>
                          <img src={Cart} alt="Cart" />
                          <p>Added to Cart</p>
                        </button>
                      </div>
                    )}
                    <div onClick={handleBuyNow} className="add-to-cart-btn">
                      <img src={Cart} alt="Cart" />
                      <p>Buy Now</p>
                    </div>
                  </div>
                </div>
                <div className="prod-action-mobile">
                  {!isProductInCart ? (
                    <div className="btns-prod">
                      <div onClick={handleAddToCart} className="add-to-cart-btn-mobile">
                        <img src={Cart} alt="Cart" />
                        <p>Add to Cart</p>
                      </div>
                    </div>
                  ) : (
                    <div className="btns-prod">
                      <button className="add-to-cart-btn-mobile disable" disabled={true}>
                        <img src={Cart} alt="Cart" />
                        <p>Added to Cart</p>
                      </button>
                    </div>
                  )}
                  <div onClick={handleBuyNow} className="buy-now-btn-mobile">
                    {/* <img src={Cart} alt="Cart" /> */}
                    <p>Buy Now</p>
                  </div>
                </div>
                <PincodeAvl />
              </div>
            </div>
            <div className="prod-menu-holder">
              <div className="prod-menu">
                {detailsFocus ? (
                  <div>
                    <a className="focus-menu" onClick={handleDetails}>
                      Details
                    </a>
                    <div className="menu-focus-line"></div>
                  </div>
                ) : (
                  <>
                    <a className="non-focus-menu" onClick={handleDetails}>
                      Details
                    </a>
                  </>
                )}
                {shippingFocus ? (
                  <div>
                    <a className="focus-menu" onClick={handleShipping}>
                      Shipping
                    </a>
                    <div className="menu-focus-line"></div>
                  </div>
                ) : (
                  <a className="non-focus-menu" onClick={handleShipping}>
                    Shipping
                  </a>
                )}
                {returnFocus ? (
                  <div>
                    <a className="focus-menu" onClick={handleReturn}>
                      Return
                    </a>
                    <div className="menu-focus-line"></div>
                  </div>
                ) : (
                  <a className="non-focus-menu" onClick={handleReturn}>
                    Return
                  </a>
                )}
              </div>
              <div className="prod-content">
                {detailsFocus && data.productDescription && (() => {
                  // Split the description by semicolons
                  const parts = data.productDescription.split(';');

                  // Process each part to remove content after "//"
                  const cleanedParts = parts.map(part => {
                    const commentIndex = part.indexOf('//');
                    return commentIndex !== -1 ? part.substring(0, commentIndex).trim() : part.trim();
                  });

                  // Extract the last part for further processing
                  const lastPart = cleanedParts.pop();
                  const underscoreIndex = lastPart.indexOf('_');

                  // Check if there is no underscore in the last part
                  if (underscoreIndex === -1) {
                    // Return the paragraph directly if no underscore is found
                    return (
                      <>
                        {cleanedParts.map((part, index) => (
                          <p key={index}>{part}</p>
                        ))}
                        <p>{lastPart}</p>
                      </>
                    );
                  }

                  // Split the last part into description and details based on the underscore
                  const descriptionPart = lastPart.substring(0, underscoreIndex).trim();
                  const detailsPart = lastPart.substring(underscoreIndex + 1).split('_').map(detail => detail.trim());

                  // Determine headings based on the series or category
                  let headings;
                  if (series.includes("cargo") || series.includes("joggers") || series.includes("chinos") ||
                    category.includes("cargo") || category.includes("joggers") || category.includes("chinos")) {
                    headings = ["Fit", "Material", "Occasion", "Closure", "Type", "Pockets"];
                  } else {
                    headings = ['Fit', 'Material', 'Occasion', 'Wash', 'Print', 'Sleeve'];
                  }

                  // Map details to headings
                  const manufacturingDetails = detailsPart.map((detail, index) => (
                    <div className="mfg-dts" key={index}>
                      <h4>{headings[index]}</h4>
                      <p>{detail}</p>
                    </div>
                  ));

                  return (
                    <>
                      {cleanedParts.map((part, index) => (
                        <p key={index}>{part}</p>
                      ))}
                      <p>{descriptionPart}</p>
                      <div className="manufacturing-details">
                        {manufacturingDetails}
                      </div>
                    </>
                  );
                })()}

                {shippingFocus && <p>{ProductObject.shipping}</p>}
                {returnFocus && <p>{ProductObject.return}</p>}
              </div>

            </div>
            <div className="prod-menu-holder">
              <SuggestElements />
            </div>
            <div className="faq-section">
              <FAQ />
            </div>
          </div>
        </>)}

      <Footer />
      <ToastContainer style={{ zIndex: "1000000" }} />
    </div>
  );
};

export default ProdDesc;
