import React, { lazy, Suspense } from 'react';
import Navbar from '../../component/Navbar/Navbar';
import Hero from '../../component/Hero/Hero';
import TopCategories from '../../component/TopCategories/TopCategories';
import TopProducts from '../../component/TopProducts/TopProducts';
import TopProductMobile from '../../component/TopProductMobile/TopProductMobile';
import NavTop from '../../component/NavTop/NavTop';

// Lazy load less critical components
const AboutGrid = lazy(() => import('../../component/AboutGrid/AboutGrid'));
const SuggestProducts = lazy(() => import('../../component/SuggestProducts/SuggestProducts'));
const Footer = lazy(() => import('../../component/Footer/Footer'));

const Home = () => {
  return (
    <div>
      <NavTop />
      <Navbar />
      <div style={{ marginTop: '7vh' }} className='top-product-mobile'>
        <TopProductMobile />
      </div>
      <Hero />
      {/* <TopCategories /> */}
      <TopProducts />
      <Suspense fallback={<p>Loading suggestions...</p>}>
        <SuggestProducts />
      </Suspense>
      <Suspense fallback={<p>Loading about section...</p>}>
        <AboutGrid />
      </Suspense>
      <Suspense fallback={<p>Loading footer...</p>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default Home;
